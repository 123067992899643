.inputBox {
	width: 100%;
  height: 56px;
	padding: 12px 20px;
	display: inline-block;
	border: 1px solid #d0d5dd;
	border-radius: 5px;
	box-sizing: border-box;
	color: black;
  font-size: 1rem;
}

.inputBox:hover {
	border-color: black;
}

.inputBox:disabled {
	border: none;
  background-color: #1010100D;
  ;
}

.inputBoxLabel {
  font-size: 1rem;
}

.validationMsg {
  color: var(--Brand, #ff2459);
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .inputBox {
    height: 48px;
  }

  .inputBoxLabel {
    font-size: 14px;
  }
}