.noTicketAvailable {
  font-family: General Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #101010;
  width: 100%;
  height: 72px;
  padding: 20px 20px 20px 24px;
  border-radius: 12px;
  border: 1px;
  gap: 16px;
  border: 1px solid #f0f0f0;
}
.lookingToSellContainer {
  width: 100%;
  padding: 24px 24px 20px 24px;
  border-radius: 12px;
  gap: 16px;
  background: #fff2f2;
  margin-top: 32px;
}
.lookingToSellCTA {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: #101010;
}
.lookingToSellText {
  font-family: General Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.01em;
  text-align: left;
}
.lookingToSellDesc {
  font-family: General Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-top: 11px;
  margin-bottom: 20px;
}

.ticketsTitle {
  font-family: General Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #101010;
  margin-bottom: 20px;
}
