.progress-bar-container {
    width: 100%;
    margin: auto;
    position: absolute;
    left: 0;
  }

  .progress-bar {
    height: 3px;
    background-color: #ff245b30;
    position: relative;
  }

  .progress {
    height: 100%;
    background-color: #ff2459;
    transition: width 0.3s ease-in-out;
  }

  .step-buttons {
    margin-top: 10px;
  }

  .step-buttons button {
    margin-right: 10px;
  }

@media screen and (max-width: 600px){
  .progress-bar-container{
    width: 100%;
    left: 0;
  }
}