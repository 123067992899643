.noeventscontainer {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  width: 100%;
  padding: 0;
  margin: 0; /* Remove margins that may be inherited */
  box-sizing: border-box;
  color: #101010; /* Black text */
  font-size: 22px;
}

.noeventsimage {
    width: 400px; /* Initial width */
    height: 280px; /* Initial height */
    max-width: 100%; /* Ensures it does not exceed the width of its container */
    max-height: 100%; /* Ensures it does not exceed the height of its container */
    object-fit: contain; /* Keeps the aspect ratio intact while fitting to container */
    margin: 30px
  }
  
  
.noeventsbutton {
    display: flex;
    flex-direction: row;    
    align-items: center;
    justify-content: center;
    padding: 15px 20px;        
    color: #101010; /* Black text */
    border: 1px solid #E7E7E7;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

@media (min-width: 480px) {
.noeventsbutton {
    margin-top: 20px; /* or 40px */

}
}

@media (max-width: 480px) {
.noeventsbutton {
    margin-top: 20px; /* or 40px */

}
}
  