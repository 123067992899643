.sentMailImage {
  width: 100%;
  height: 180px;
}

.subTextEmail {
  color: var(--Brand, #ff2459);
}

.loginHeading {
  font-weight: 600;
  font-size: 28px;
}

.resendButton {
  border: 1px solid #D0D5DD;
  justify-content: center;
  margin-top: 2rem;
  height: 56px;
}

.resendButton:disabled {
  color: #B7B7B7;
}

.continueEmail {
  height: 56px;
  margin-top: 0.75rem;
  background-color: var(--Brand, #ff2459);
}

.continueEmailButton {
  height: 56px;
  margin-top: 0.75rem;
  background-color: white;
  color: #101010;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Grey-30, #E7E7E7);
  background: #FDFDFD;
}

.allSignIn {
  color: var(--Brand-Parimary, #FF2459);
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  border: none;
  display: flex;
  gap: 4px;
  justify-content: center;

}

@media screen and (max-width: 600px) {
  .resendButton {
    height: 48px;
  }

  .continueEmail {
    height: 48px;
    margin-top: 0px;
  }

  .loginHeading {
    font-size: 24px !important;
  }
}