@import url('https://api.fontshare.com/v2/css?f[]=general-sans@701,200,500,301,201,300,601,600,401,501,400,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: 'general-sans', sans-serif;
  font-weight: 400;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

/* Basic class for tailwind */

.linear-bg {
  background: linear-gradient(269.82deg, #07091c00 0.17%, #080a1ad6 99.87%);
}

.hoveres {
  @apply hidden;
}
.hovered:hover .hoveres {
  @apply flex-colo;
}

.flex-colo {
  @apply flex flex-col justify-center items-center;
}

.flex-rows {
  @apply flex flex-row justify-center items-center;
}

.flex-btn {
  @apply flex justify-between items-center;
}

.transitions {
  @apply transition duration-300 ease-in;
}

.zIndex {
  z-index: 1000;
}

.bg-pink {
  background-color: #ff3c6b;
}

/* search */
/* width */
.searchItemsContainer::-webkit-scrollbar {
  width: 0.4rem;
}

/* Track */
.searchItemsContainer::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.searchItemsContainer::-webkit-scrollbar-thumb {
  background: #ff3c6b;
  border-radius: 0.25rem;
}

/* Handle on hover */
.searchItemsContainer::-webkit-scrollbar-thumb:hover {
  background: #dc4568;
}

.lh-1 {
  line-height: 1;
}

.ticket-group .ant-collapse-expand-icon {
  margin-top: 0.6rem;
}

#otpless-floating-button{
  display: none;
}

.swiper-slide {
  max-width: 335px;
}

input::placeholder{
  font-size: 1rem;
  color:black;
}


.text-semibold{
  color: var(--Black, var(--Black, #101010));
/* Text lg/Semibold */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px;
}