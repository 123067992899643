* {
	font-family: General Sans;
	font-style: normal;
}

.normal {
	color: var(--secondary-grey, #717171);
	font-weight: 500;
}

.all {
	font-family: 'general-sans';
}

h1 {
	color: var(--black, #1c1c1c);
	font-weight: 600;
	line-height: 140%;
	font-size: 3rem;
}
p {
	color: var(--secondary-grey, #717171);
	font-size: 16px;
	font-family: General Sans;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
}
h2 {
	color: var(--black, #1c1c1c);
	font-size: 1rem;
	font-weight: bold;
	line-height: 140%;
	/* padding: 2rem 20rem 2rem; */
}
button {
	color: var(--brand, #ff3c6b);
	font-size: 1rem;
	font-weight: 500;
	line-height: 100%;
	-left: 10rem;
}

/*  */

.container {
	width: 100%;
	height: auto;
	padding: 8rem 0;
}

.header {
	width: fit-content;
	padding-left: 30vw;
}

.bodyContainer {
	width: 70%;
	padding-left: 30%;
	padding-top: 2rem;
}

.title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.displayPic {
	display: flex;
	padding-top: 1rem;
	gap: 2rem;
	align-items: center;
}

.picContainer {
	width: 7rem;
	height: 7rem;
	/* background-color: black; */
	border-radius: 50%;
	overflow: hidden;
}

.imag {
	height: 100%;
	width: 100%;
}

.nameContainer {
	font-size: 1.2rem;
}

.fileContainer {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.verified > * {
	padding-top: 2rem;
}

.verifiedContainer {
	display: flex;
	justify-content: space-between;
	padding-top: 1rem;
}

.editContainer {
	padding-top: 2rem;
}

.textInput {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	border: 2px solid #ccc;
	border-radius: 5px;
	box-sizing: border-box;
	color: black;
}

.textInput:hover {
	border-color: black;
}

.dropdown {
	background-color: transparent;
	border-radius: 5px;
	border: 1px solid #ccc;
}

.submitBtn {
	background-color: var(--brand, #ff3c6b);
	color: white;
	padding: 0.5rem 2rem;
	border-radius: 7%;
	margin-top: 0.5rem;
	font-size: 12px;
}

/*  */

@media screen and (max-width: 600px) {
	.header {
		width: 100vw;
		padding: 0 2rem;
	}

	h1 {
		font-size: 1.5rem;
		font-weight: 600;
	}

	p {
		font-size: 14px;
	}

	.bodyContainer {
		width: 100%;
		padding: 2rem;
	}

	.nameContainer {
		font-size: 1rem;
	}

	.verifiedContainer {
		flex-direction: column;
	}
}
