* {
  font-family: "General Sans", sans-serif;
  font-style: normal;
}

.normal {
  color: var(--secondary-grey, #717171);
  font-weight: 500;
}


.flex {
  display: flex;
}
h1{
  font-size:2.25rem;
}

.nolistcontainer{
  display: flex;
  width: 35rem;
  height: 14.5rem;
  padding: 1rem;
  padding-left: 2.25rem;
  padding-right: 0;
  justify-content: flex-end;
  align-items: center;
  margin:1px auto;
  gap: 1.25rem;
  flex-shrink: 0;
  border-radius: 0.75rem;
  border: 1px solid var(--date-stroke, #F0F0F0);
  background: #FFF;
  margin-top: 2.25rem;
}

.nolistheader {
  color: var(--black, #101010);
 
  font-size: 2.25rem;
 
  font-weight: 600;
  
  padding-left: 29%;/* 3.15rem */
}
.nolisttext{
  
  gap: 1.25rem;
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: auto;
  flex: 1
}
.nolisthead{
  color: var(--black, #101010);
  
  padding-top:1rem;
  font-size: 1.25rem;
 
  font-weight: 500;
  line-height: 140%; 
  letter-spacing: -0.0125rem;
}
.nolistcontent{
  color: var(--secondary-grey, #717171);
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%; 
 
  
}
.nolistimg{
  text-align: right;
  width: 16.875rem;
  height: 14.5rem;
  flex-shrink: 0;
  border-bottom-right-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.listContainer {
  padding: 4.5rem 0;
  width: 100%;
  height: auto;
  position: relative;
  min-height: 90vh;
}

.ticketContainer {
  width: 40%;
  padding: 1.25rem;
  width: 40%;
  padding: 1.25rem;
  display: flex;
  margin: 0.5rem auto;
  height:fit-content;
  max-width: 35rem;
  height:auto;
  min-width: 32rem;
  
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  align-self: stretch;
  border-radius: 0.75rem;
  border: 1px solid var(--date-stroke, #F0F0F0);
  background: var(--white, #FFF);

}
.pastHeader{
  padding-left: 28%;
  margin:1.5rem;
  color: var(--black, #101010);
font-family: General Sans;
font-size: 1.5rem;
font-style: normal;
font-weight: 600;
line-height: normal;
white-space: nowrap;
}

.listHeader {
  color: var(--black, #1C1C1C);
  font-size: 2rem;
  font-weight: 600;
  line-height: 140%;
  padding-left: 28%;
  margin: 1.5rem;
}

.artist{
  display: flex;
  flex-direction: column;
  color: var(--black, #1c1c1c);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: normal;
}

p {
  color: var(--black, #1C1C1C);
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
}


.ticket-img {
  width: 7.625rem;
  height: 5.625rem;
  border-radius: 0.5rem;
  display: flex;
  flex-shrink: 0;
  margin:0;
  margin-left: 0.5rem;
  margin-right: 1rem;

}

.ticketInfo {
  padding: 0.1rem;
  padding-left: 1.25rem;
  color: var(--black, #1C1C1C);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;

}

.ticketInfo>.ticket-img {
  margin-right: 1rem;
}


.show {
  padding-top: 0.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticketList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96.5%;
  margin:0;
  margin-left: 0.5rem;
}

.count h3{
  color: #1c1c1c;
  font-weight: 600;
  font-size: 1rem;
  /* width:20rem;
  align-items: justify-center; */
}
.ticcount{
  color: var(--black, #101010);
  font-family: General Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.category {
  color: #1c1c1c;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

}

.ticketCost {
  color: var(--green-text, #009D64);
  font-size: 1rem;
  font-weight: 600;
  text-align: right;

}

.tick {
  text-align: right;
  color: #1c1c1c;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}

.buttons {
  display: flex;
  height: 3rem;
  padding: 0.25rem;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  border-radius: 0.5rem;
  width: 100%;
  flex:1 0 0 ;
}

.primary-button {
  display: flex;
  height: 3rem;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  border: 1px solid var(--brand, #ff3c6b);
  background: var(--white, #fff);
  color: #ff3c6b;
  font-weight: 600;
  width: 50%;
}

.sec-button {
  display: flex;
  height: 3rem;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  color: #fff;
  background: var(--brand, #ff3c6b);
  font-weight: 600;
  width: 50%;
}

.sold-out {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;

  border-radius: 0.3rem;
  background: #E5FFF6;
  pointer-events: none;
  width: 100%;
  height: 3rem;
  color: #009D64;
}

.editListContainer {
  width: 100%;
}

.listEditForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
}

.listEditForm label {
  color: var(--black, #1C1C1C);
  font-size: 1rem;
  font-family: General Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.frame {
  display: flex;
  height: 3.75rem;
  padding: 1rem 1.5rem;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--field-stroke, #D0D5DD);
  background: var(--white, #FFF);
  font-size: 100%;
  width: inherit;
}

.frame > option {
  overflow-x: hidden ;
}

.textInputt {
  width: fit-content;
}

.selectInput {
  padding: 0.5rem;

}

.rupee {
  display: flex;
  font-weight: bolder;
  padding-right: 1rem;
  font-size: 1rem;
}

.numberInput,
.priceInput {
  font-size: 1.25rem;
  color: #1C1C1C;


}

.numberInput select {
  width: 100%;
  appearance: none;
  padding: 0.5rem 1rem;
  border: 1px solid var(--field-stroke, #D0D5DD);
  background: var(--white, #FFF);
  font-size: 1.25rem;
  color: #1C1C1C;
}

.numberInput::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4rem 0.4rem 0 0.4rem;
  border-color: #717171 transparent transparent transparent;
}

.numberInput select option {
  background-color: var(--white, #FFF);
}

.numberInput select option:hover {
  background-color: #D0D5DD;
}
.errorI{
  width:1rem;
  height:1rem;
  margin:0 0.25rem;
  display: inline;
}
.errorMessage {
  color: #DF1B41;
  font-size: 0.875rem;
  margin-top: 0.55rem;
}

.error {
  border-color: #DF1B41;
}
.delete-confirmation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup {
  width: 40%;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.popup-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.popup-message {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.popup-buttons {
  justify-content: space-between;
  display: flex;
  height: 3.5rem;
  padding: 0.5rem 0.5rem;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup {
  width: 500px;
  height: fit-content;
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.popup-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.popup-message {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
}

.popup-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
@media screen and (max-width: 850px) {
  .pastHeader{
    padding-left: 28%;
    margin:1.5rem;
    color: var(--black, #101010);
  font-family: General Sans;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  }
  
  .listHeader {
    color: var(--black, #1C1C1C);
    font-size: 2rem;
    font-weight: 600;
    line-height: 140%;
    padding-left: 20%;
    margin: 1.5rem;
  }
  
}


@media screen and (max-width: 650px) {

  .listContainer {
    margin: 0;
    margin-top: 5rem;
    padding: 1.5rem;
    width: 100%;
    height: auto;
    position: relative;
    font-style: inherit;
    min-height: 80vh;
  }
  h3{
    font-size: 1.1rem;
  }
  
.nolistcontainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin:1px auto;
  gap: 1.25rem;
  flex-shrink: 0;
  margin:1px auto;
  gap: 1.25rem;
  flex-shrink: 0;
  border-radius: 0.75rem;
  border: 1px solid var(--date-stroke, #F0F0F0);
  background: #FFF;
  margin-top: 2.25rem;
  display: flex;
  width: 20.4375rem;
  height: fit-content;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  white-space: nowrap;
}

.nolistheader {
  color: var(--black, #101010);
 
  font-size: 2.25rem;
 
  font-weight: 600;
  
  padding-left: 1.5rem;/* 3.15rem */
}
.nolisttext{
  
  gap: 1.25rem;
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: auto;
  flex: 1
}
.nolisthead
{ padding-top: 0;
  color: var(--black, #101010);
  font-family: General Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.01rem;
}
.nolistcontent{
  color: var(--secondary-grey, #717171);
  font-family: General Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.00438rem;
}
.nolistimg{
 display: none;
}
.pastHeader{
  width: 100%;
    padding-left: 5%;
    margin:0.25rem;
  display: flex;
  align-items: flex-start;
  color: var(--black, #101010);
  font-family: General Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; 
  white-space: nowrap; /* 1.75rem */
}
  .count h3{
    font-size: 1rem;
    font-weight: 600;
    color: var(--black, #101010);
  }
  .category {
      color: var(--black, #101010);
      font-family: General Sans;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
  }
  .listHeader {
    color: var(--black, #1C1C1C);
    padding: 0 0.25rem;
    font-size: 2rem;
    font-weight: bold;
    line-height: 140%;
    width: 100%;
    padding-left: 5%;
    margin:0.25rem;
  }
  h1{
    font-size: 2rem;
  }

  p {
    font-size: 12px;
    color: var(--black, #101010);
    font-size: 0.875rem; 
    font-weight: 500;
    line-height: 140%;
  }
c
  .venue{
    padding-bottom: 0.5rem;
    font-size: 12px;
    color: var(--black, #101010);
    font-size: 0.875rem; 
    font-weight: 500;
    line-height: 140%;
  }
  .venuee{
    font-size: 12px;
    color: var(--black, #101010);
    font-size: 0.875rem; 
    font-weight: 500;
    line-height: 140%;
  }

  .ticketContainer {

    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 0.75rem;
    border: 1px solid var(--date-stroke, #F0F0F0);
    padding: 1rem;
    margin: 1rem;
    min-width: 18rem;
    min-height: 16rem;
  }

  .show {
   
    display: block;
    align-items: flex-start;
    gap:1rem;
    width: 100%;
    justify-content: flex-start;
  }

  .ticket-img {
    width: 18.4375rem;
    height: 9.21875rem;
    display: flex;
    border-radius: 0.5rem;
    margin-right: 0.8rem;
    margin-left: 0;

  }

  .ticketInfo {
    padding: 0.75rem 0.25rem;
    padding-top: 0;
    padding-left: 0;
    line-height: 2px;
    width: auto;
    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin:0;


  }

  .artist {
    font-size: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    font-weight: 600;
    word-break: keep-all;
    white-space: pre-wrap;
    color: var(--black, #101010);
  }
  .ticketList {
    margin:0;
  }
  .count {
    font-size: 0.9rem;
  }


  .cost {
    padding: 0.25rem;

  }

  .ticketCost {
    text-align: right;
    color: var(--green-text, #009D64);
    font-family: General Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tick {
    text-align: right;
    font-size: 0.875rem;
  }

  .buttons {
    display: flex;
    height: 2rem;
    padding: 0.5rem 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 0;

  }

  .main {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .primary-button,
  .sec-button {
    width: 100%;
  }

  .edit,
  .mark {
    width: 50%;
    display: flex;


  }

  .sold-out {
    width: 100%;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }

  .editListContainer {
    padding: 0.5rem;
  }

  .listEditForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
  }

  .listEditForm label {
    color: var(--black, #1C1C1C);
    font-size: 1rem;
    font-family: General Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .frame {
    display: flex;
    height: 3.5rem;
    padding: 1rem 1.5rem;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid var(--field-stroke, #D0D5DD);
    background: var(--white, #FFF);

  }

  /* .selectInput{
      padding:0.5rem;
      
    }
    select.numberInput {
      height: 30%;
      max-height:50%; Adjust the maximum height as per your requirement 
      overflow-y: auto;
    } */
  .numberInput {
    position: relative;
    display: inline-block;
  }

  .numberInput select {
    font-size: 1rem;
    ;
  }

  .rupee {

    display: flex;
    margin: 1rem;
    font-weight: bolder;
    padding-right: 1rem;
    font-size: 1.15rem;
  }

  .numberInput,
  .priceInput {
    font-size: 1.25rem;
    color: #1C1C1C;
  }

  .textInputt {
    width: 100px;
  }

  .delete-confirmation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup {
    width: 70%;
    height: fit-content;
    padding: 1rem;
    padding-bottom: 10%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  .popup-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .popup-message {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  .popup-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex-wrap: nowrap;
  }

  .popup-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    margin-bottom: 8px;
    cursor: pointer;
  }

  select {
    width: auto;
  }

  .selectInput {
    padding: 0.25rem;

  }
}
