.backgroundLayout {
  background: var(--Brand-Light, #fff2f2);
}

.verify {
  position: relative;
  width: fit-content;
  width: 560px;
  height: auto;
  padding: 36px;
  margin: 104px auto 0px;
  border-radius: 12px;
  border: 1px solid #f0f0f0;
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.nextBut {
  display: flex;
  width: 100%;
  /* max-width: 70%; */
  height: 56px;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: #fff;
  border-radius: 8px;
  background: var(--Brand, #ff2459);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--White, #fff);
  text-align: center;
  font-family: "General Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.proceedButton {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 72px);
  text-align: center;
  font-family: "General Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.verifyHeader {
  color: var(--Black, #101010);
  font-family: General Sans;
  font-size: 2rem;
  height: 30px;

  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.verifyHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 56px;
  align-self: stretch;
}

.verify_cont {
  color: #101010;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-top: 1rem;
}

.verifyMainContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.verifyStep1 {
  display: flex;
  gap: 12px;
}

.stepIcon {
  width: 36px;
  height: 36px;
}

.findTicket {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
}

.inputContainer {
  display: flex;
  padding: 16px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px dashed #c6c6c6;
  background: #fff;
}

.imageContainer {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 18.287px;
  align-self: stretch;
  background: #f5f5f6;
}

.mailImage {
  display: flex;
  height: 64px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 3.657px;
  background: var(--Neutral-Grey-13, #F5F5F6);
}

.copyDiv {
  display: flex;
  gap: 1rem;
}
.copiedDiv {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}
.tmtmail {
  color: #222;
  font-family: General Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.copyicon {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  cursor: pointer;
}

.forward {
  color: #ff3c6b;
  text-align: center;
  font-family: General Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}

.wait {
  padding: 1.5rem;
  color: var(--gray-500, #667085);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 0;
  padding-top: 1rem;
}
.continuebut {
  display: flex;
  width: 21.875rem;
  height: 2.75rem;
  padding: 0.5rem 0.875rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  color: #f03864;
  background-color: #f03864;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 1rem;
}
.continetext {
  color: var(--White, #fff);
  text-align: center;
  font-family: General Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.email_cont {
  color: var(--Secondary-grey, #717171);
  font-family: General Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-left: 2.7rem;
}

.loaderOverlay {
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(34, 34, 34, 0.6);
}
.loaderContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  margin: auto;
}
/* verify popup  */

.popupContainer{
  width: 528px;
  height: 384px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background-color: #FFF;
  margin: auto;
  padding: 36px 36px 24px 36px;
  gap:20px;
}

.popupIconContainer{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.popupTextContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  gap:12px;
}

.popupBtnContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.popupH1{
  font-family: General Sans;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.popupH2{
  font-family: General Sans;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color:#ff2459;
}

.popupPara{
  font-family: General Sans;
  font-size: 16px;
  color: #101010;
  line-height: 24.4px;
  font-weight: 400;
}

.htfBtn{
  display: flex;
  width: 100%;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color:#ff2459;
  text-decoration: underline;
  border-radius: 8px;
  text-align: center;
  font-family: "General Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.skeletonLoadingText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  margin: 24px 0px;
}

.skeletonLoadingText > div:nth-child(1) {
  color: #000;
  text-align: center;
  font-family: "General Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}
.skeletonLoadingText > div:nth-child(2) {
  color: var(--Black, var(--Black, #101010));
  text-align: center;
  font-family: "General Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.042px;
}

.backBut {
  color: var(--Black, #222);
  text-align: center;
  font-family: General Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration-line: underline;
  margin: 1rem;
}

.ticketVerifyDiv {
  width: 350px;
  border-radius: 12px;
  background: var(--Green-Light, #e5fff6);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4),
    0px 6px 6px -6px rgba(0, 0, 0, 0.16);
  padding: 24px;
}

.ticketVerifyInternalDiv {
  padding: 12px 12px 0px;
  color: var(--Black, var(--Black, #101010));
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.15);
}

.ticketTitle {
  color: var(--Black, var(--Black, #101010));
  font-family: General Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  margin-top: 44px;
}

.ticketDetails {
  color: var(--Black, var(--Black, #101010));
  font-family: General Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0px 24px 0px;
}

.ticketAmount {
  color: var(--Black, var(--Black, #101010));
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 17px;
  margin: 0px -12px 0px;
  border-radius: 0px 0px 12px 12px;
  background: var(--Green-Light, #e5fff6);
  align-items: center;
}
.ticketAmount > div {
  justify-content: center;
}

.ticketsVerifiedText {
  color: var(--Green, #009d64);
  text-align: center;
  font-family: General Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  margin-top: 24px;
}
.errorstate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 16px;
}

.errorBtn {
  display: flex;
  height: 48px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  color: #fff;
  background: var(--Brand, #ff2459);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
}

.imageSkeleton {
  height: 143px !important;
  width: 100% !important;
  border-radius: 6px;
}

.titleSkeleton {
  border-radius: 6px;
  height: 44px !important;
  width: 100% !important;
}

.descSkeleton {
  border-radius: 6px;
  width: 173px !important;
  height: 19px !important;
  overflow: hidden;
}
.smallSkeleton {
  border-radius: 6px;
  width: 73px !important;
  height: 19px !important;
  overflow: hidden;
}
.forwardMailAcknowledge {
  font-family: General Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.003em;
  text-align: left;
  color: #101010;
  position: absolute;
  bottom: 125px;
  width: 90%;

}

.bottomButton{
  height: 80px;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .bottomButton{
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    background-color: white;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0px 24px 24px;
    height: 110px;
  }

  .forwardMailAcknowledge{
    margin-top: 24px;

  }


  .backgroundLayout {
    position: relative;
  }

  .verify {
    position: static;
    width: auto;
    height: 800px;
    overflow-y: scroll;
    margin: 0 24px;
    margin-bottom: 12px;
    padding: 0;
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: none;
    box-shadow: none;
  }

  .backgroundLayout {
    background: #fff;
  }

  .proceedButton {
    width:100%;
    position:static;
    bottom: 0;
  }

  .verifyHeader {
    padding: 0;
    width: 100%;
    height: auto;
    font-size: 24px;
  }

  .verify_cont {
    padding: 0;
    font-size: 0.875rem;
  }

  .verifyMainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }

  .verifyHeaderContainer{
    gap: 12px;
  }


  .loaderContainer {
    display: flex;
    width: 343px;
    max-width: 95%;
    /* padding: 24px; */
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .errorstate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    margin-bottom: 16px;
  }

  .errorBtn {
    display: flex;
    height: 48px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    color: #fff;
    background: var(--Brand, #ff2459);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .tailspin {
    width: 3rem;
    align-self: center;
  }

  .wait {
    font-size: 0.8rem;
  }

  .forwardMailAcknowledge {
        bottom: 94px;
  }

  .popupContainer{
    height: 336px;
    width: 327px;
    padding:24px 24px 16px 24px;
    gap: 20px;
  }

  .popupTextContainer{
    line-height: 21px;
  }

  .popupBtnContainer{
    gap:4px;
  }

  .popupH1{
    font-size: 16px;
    line-height: 21px;
  }

  .popupH2{
    font-size: 16px;
  }

  .popupPara{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .nextBut{
    padding: 8px 14px;
  }
}

@media screen and (max-width: 375px) {
  .verifyHeader {
    font-size: 23px;
  }
}

@media screen and (max-width: 350px) {
  .verifyHeader {
    font-size: 22px;
  }
}

@media screen and (max-width: 350px) {
  .verifyHeader {
    font-size: 21.5px;
  }
}

@media screen and (max-width: 330px) {
  .verifyHeader {
    font-size: 20px;
  }
}

@media screen and (max-width: 310px) {
  .verifyHeader {
    font-size: 19px;
  }
}

@media screen and (max-width: 300px) {
  .verifyHeader {
    font-size: 18px;
  }
}
