.transfer {
  display: flex;
  width: 100vw;
  padding: 120px 130px;
  align-items: center;
  justify-content: space-between;
  gap: 56px;
  background: var(--Black, #101010);
  overflow: hidden;
}

.transferImgContainer{
  display: flex;
  width: 566px;
  height: 540px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.transferImg{
  width: inherit;
  height: 540px;
  flex-shrink: 0;
  border-radius: 32px;
  background: var(--White, #FFF);
}

.transferPlatform{
  display: flex;
  width: 566px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
}

.transferHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.transferTitle{
  color: #fff;
  font-family: "General Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.64px;
}

.transferSubTitle{
  color: var(--White, #FFF);
  font-family: "General Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}

.bms {
  width: 36rem;
  max-width: 36rem;
  min-width: 20rem;
  display: flex;
  padding: 16px 24px;
  margin-top: 20px;
  margin-bottom: 1.5rem;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--White, #fff);
  position: relative;
}
.insider {
  position: relative;
  width: 36rem;
  max-width: 36rem;
  min-width: 20rem;
  display: flex;
  padding: 16px 24px;
  margin-top: 20px;
  margin-bottom: 1.5rem;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--White, #fff);
}
.til {
  display: flex;
  text-align: left;
  position: relative;
  padding-left: 1rem;
  padding-right: 35%;
}
.learn {
  color: var(--Brand, #ff2459);
  font-family: General Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  position: absolute;
  right: 24px;
}
.phone {
  display: flex;
  flex-flow: row-reverse;
  width: max-content;
  height: 40.3125rem;
  overflow: hidden;
}

/* verification section */

.verify_Container{
  display: flex;
  width: 100%;
  padding: 120px 130px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 80px;
  background: var(--Brand, #FF2459);
}

.verify_HeaderContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.verify_Header{
  color: var(--White, #FFF);
  text-align: center;

  /* Headling/Large */
  font-family: "General Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 76.8px */
  letter-spacing: -0.64px;
}

.verify_Main{
  display: flex;
  align-items: flex-start;
  gap: 64px;
  align-self: stretch;
}

.verify_features{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.verify_card{
  display: flex;
  padding: 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.10);
}

.verify_cardImg{
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
}

.verify_cardContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.verify_cardTitle{
  color: var(--White, #FFF);
  font-family: "General Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
}

.verify_cardText{
  align-self: stretch;
  color: var(--White, #FFF);

  /* Text/Large */
  font-family: "General Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}

.verify_ticket{
  display: flex;
  width: 566px;
  height: 607px;
  padding: 25.5px 74px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
  gap:24px;
  background: var(--Green-Light, #E5FFF6);
}

.verify_success{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.verify_successImg{
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
}

.verify_successText{
  color: var(--Green, #009D64);
  text-align: center;
  font-family: "General Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.verify_event{
  display: flex;
  height: 384px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
}

.verify_eventImg{
  width: 330px;
  height: 404px;
  border-radius: 12px;
  fill: var(--White, #FFF); 
  filter: drop-shadow(0px 2px 22.8px rgba(0, 0, 0, 0.13));
}

/* Testimonials */

.testimonialContainer{
  display: flex;
  padding: 120px 130px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  background:#FFF;
}

.testimonialTitle{
  color:#101010;
  text-align: center;
  font-family: "General Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; 
  letter-spacing: -0.64px;
}

.titleHighlight{
  color:#FF2459;
  font-family: "Architects Daughter",cursive;
  font-weight: 400;
}

.tweetContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}

.testimonialCardContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 calc(33.33% - 20px);
  padding: 36px;
  gap: 20px;
  border-radius: 16px;
  border: 1px solid var(--Date-Stroke, #F0F0F0);
  background: var(--White, #FFF);
  box-shadow: 0px 0.789px 1.579px 0px rgba(16, 24, 40, 0.05);
}

.testimonialHeader{
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.Tdp{
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background: lightgray 0px -6.06px / 100.03% 140.186% no-repeat;
}

.TuserDetails{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Tusername{
  color: #101010;
  font-family: "General Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.395px;
}

.TuserId{
  color:  #717171;
  font-family: "General Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.395px;
}

.tweet{
  align-self: stretch;
  color:  #101010;
  font-family: "General Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
  letter-spacing: -0.4px;
}

/* Get in Touch */

.gitContainer{
  display: flex;
  padding: 120px 130px;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
  background:#101010;
}

.gitHeaderContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 36px;
}

.gitHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.gitTitle{
  width: 566px;
  color:#FFF;
  font-family: "General Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.64px;
}

.gitSubtitle{
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  align-self: stretch;
}

.gitButton{
  display: flex;
  height: 64px;
  padding: 12px 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 950px;
  background: var(--Brand, #FF2459);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
}

.gitButton:hover{
  background: var(--Brand, #af1239);
  
}

.gitImgContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.gitImg{
  width: 100%;
  height: 100%;
  border-radius: 32px;
  object-fit: scale-down;
}

.marquee{
  position: absolute;
  bottom:0;
  left:-15px;
  display: flex;
  width: max-content;
  height: 83.515px;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  z-index: -1;
  /* -moz-animation: scroll-left 20s linear infinite;
  -webkit-animation: scroll-left 20s linear infinite;
  animation: scroll-left 20s linear infinite; */
}

.marqueeTextContainer{
  display: flex;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  gap: 12px;
  /* -moz-animation: scroll-left 60s linear infinite;
  -webkit-animation: scroll-left 60s linear infinite;
  animation: scroll-left 60s linear infinite; */
}

.marqueeText{
  color: #FFF;
  text-align: center;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.10);
  font-family: "Bowlby One SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}

/* banner */
.bannerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  padding: 120px 130px;
  background:#101010;
  color: #FDFDFD;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
}

.innerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
}

.newPlatformContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 24px 8px 8px;
  border-radius: 80px;
  background: linear-gradient(
    90deg,
    rgba(255, 242, 242, 0.1) 0%,
    rgba(153, 145, 145, 0.1) 100%
  );
}

.newPlatformBadge {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 12px;
  background-color: #3D3C3C;
  border-radius: 50px;
}

.emoji {
  width: 24px;
  height: 24px;
}

.newText {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: normal;
  color: #FDFDFD;
}

.platformText {
  color: #FDFDFD;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
}

.textContainer {
  /* width: 556px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  color: #FDFDFD;
}

.heading {
  color: #FDFDFD;
  font-family: "General Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.64px;
}

.subheading {
  color: #FDFDFD;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
}

.badgeImage {
  width: 550px;
  height: 540px;
  flex-shrink: 0;
}


@media only screen and (min-width: 651px) and (max-width: 1024px) {
  .bms {
    width: 22rem;
    max-width: 22rem;
    min-width: 20rem;
    display: flex;
    padding: 1rem 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    align-items: center;
    align-self: stretch;
    border-radius: 0.5rem;
    background: var(--White, #fff);
    clear: both;

    overflow: hidden;
    white-space: nowrap;
  }
  .insider {
    width: 22rem;
    max-width: 22rem;
    min-width: 20rem;
    display: flex;
    padding: 1rem 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    align-items: center;
    align-self: stretch;
    border-radius: 0.5rem;
    background: var(--White, #fff);
    clear: both;

    overflow: hidden;
    white-space: nowrap;
  }
  .learn {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
  .til {
    padding-right: 10%;
  }
  .phone {
    display: flex;
    flex-flow: row-reverse;
    width: 18rem;
    height: 40.3125rem;
  }
  .align {
    padding: 2rem;
    padding-right: 1rem;
    padding-left: 1.5rem;
  }
  .transfer {
    padding: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .transfer {
    width: 100%;
    padding: 60px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 36px;
  }
  
  .transferImgContainer{
    width: 100%;
    height: 367px;
    padding: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }
  
  .transferImg{
    width: inherit;
    height: 367.875px;
    flex-shrink: 0;
  }
  
  .transferPlatform{
    width: 100%;
    gap: 36px;
    align-self: stretch;
  }
  
  .transferTitle{
    font-size: 24px;
    font-weight: 600;
    align-self: stretch;
    letter-spacing: 0.15px;
  }
  
  .transferSubTitle{
    font-size: 14px;
  }

  .transferbutton{
    width: 100%;
  }

  .bms {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    align-self: stretch;
    width: 100%;
  }
  .insider {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    align-self: stretch;
    width: 100%;
  }
  .til {
    display: flex;
    text-align: left;
    position: relative;
    padding-left: 0.5rem;

    padding-right: 0.5rem;
    color: #000;
    font-family: General Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    word-break: keep-all;
  }
  .learn {
    color: var(--Brand, #ff2459);
    font-family: General Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    right: 16px;
  }
  .phone {
    display: flex;
    flex-flow: row-reverse;
    width: fit-content;
    height: fit-content;
    flex-shrink: 0;
  }

  .verify_Container{
    padding: 60px 0px;
    gap: 36px;
  }
  
  .verify_Header{
    font-size: 24px;
    font-weight: 600;
    margin: 0 24px;
  }
  
  .verify_Main{
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    overflow: hidden;
  }
  
  .verify_features{
    flex-direction: row;
    width: 100vw;
    padding-left: 24px;
    padding-right: 24px;
    gap: 12px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .verify_features::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  
  .verify_card{
    width: 300px;
    padding: 24px;
    border-radius: 12px;
    flex: 0 0 auto;
  }
  
  .verify_cardImg{
    width: 36px;
    height: 36px;
  }
  
  .verify_cardContainer{
    gap: 4px;
  }
  
  .verify_cardTitle{
    font-size: 16px;
  }
  
  .verify_cardText{
    font-size: 14px;
  }
  
  .verify_ticket{
    width:  -webkit-fill-available;;
    margin: 0 24px;
    height: 414px;
    border-radius: 13px;
    gap:5px;
    padding: 33.759px 57.973px 34.442px 57.289px;
  }
  
  .verify_success{
    width: 147px;
    gap: 8px;
  }
  
  .verify_successImg{
    width: 32.737px;
    height: 32.737px;
  }
  
  .verify_successText{
    font-size: 11px;
    line-height: 12.64px; /* 125% */
  }
  
  .verify_event{
    width: 240px;
    height: 262px;
    align-items: center;
    gap: 6px;
  }
  
  .verify_eventImg{
    width: 311px;
    height: 282px;
    filter: drop-shadow(0px 1.364px 15.55px rgba(0, 0, 0, 0.13));
  }

  .testimonialContainer{
    padding: 60px 24px;
    gap: 36px;
  }

  .testimonialCardContainer {
    align-self:normal;
    flex: 0 0 327px;
    padding: 20px;
  }

  .testimonialTitle{
    font-size: 32px;
    align-self: baseline;
    text-align: left;
    font-weight: 600;
    line-height: 120%; 
    letter-spacing: -0.64px;
  }
  
  .tweetContainer{
    width: 100vw;
    padding: 24px;
    gap:12px;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .tweetContainer::-webkit-scrollbar {
    height: 5px;
  }

  .tweetContainer::-webkit-scrollbar-track {
    background-color: #dbd4d4; 
    margin: 155.5px;
    border-radius: 10px;
  }

  .tweetContainer::-webkit-scrollbar-thumb {
    background-color: #101010; 
    border-radius: 10px;
    width: 5px;
  }

  /* Get in Touch */

  .gitContainer{
    padding: 60px 24px;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
  }
  
  .gitHeaderContainer{
    gap: 24px;
    align-self: stretch;
  }
  
  .gitHeader{
    gap: 16px;
  }
  
  .gitTitle{
    width: auto;
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    align-self: stretch;
    letter-spacing: normal;
  }

  .gitSubtitle{
    font-size: 14px;
  }

  .gitButton{
    align-self: stretch;
  }

  .gitImgContainer{
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 0;
  }
  
  .gitImg{
    width: 100%;
    height: 100%;
  }

  .marquee{
    left:-15px;
    bottom: 2px;
    height: 50px;
    gap: 12px;
    z-index: 9;
  }
  
  .marqueeTextContainer{
    display: flex;
    padding: 14px 24px;
  }
  
  .marqueeText{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
  }

  /* banner */
  .mb_bannerContainer {
    display: flex;
    padding: 36px 24px 0px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .mb_contentContainer {
    /* height: 225.401px; */
    background: #E5FFF6;
    border-radius: 20px 20px 20px 20px;
  }

  .mb_newPlatformAnnounce {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 25px;
    border-radius: 20px 20px 0px 0px;
    background: #E5FFF6;
  }

  .mb_emoji {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  
  .mb_platformText{
    color: #009D64;
    font-family: "General Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 11.84px;
  }

  .mb_newPlatformContainer{
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 12px;
    background:#101010;
  }

  .mb_innerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
  }

  .mb_textContainer {
    /* width: 556px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .mb_heading {
    align-self: stretch;
    color: #FDFDFD;
    font-family: "General Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; 
    text-align: left !important;
  }
  
  .mb_subheading {
    align-self: stretch;
    color: #878787;
    font-family: "General Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; 
    text-align: left;
  }

  .mb_btn{
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color:white;
    border-radius: 81px;
    background: #FF2459;
  }
  
  .mb_badgeImage {
    width:96px;
    height:128px;
    flex-shrink: 0;
  }
}

@keyframes scroll-left {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX( calc(-100% - 2rem) );
  }
}