*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  font-family: "General Sans", sans-serif;
}

.backgroundLayout {
  background: var(--Brand-Light, #fff2f2);
}

h3 {
  color: var(--black, #101010);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 124%;
}

:root {
  --select-border: #777;
  --select-focus: #101010;
  --select-arrow: var(--select-border);
}
.flex {
  display: flex;
}

.sellContainer {
  padding: 10rem 0;
  width: fit-content;
  height: 100%;
  padding-top: 5rem;
  position: relative;
  font-style: normal;
  margin: auto;
}
.headContainer {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  min-width: 70%;
}
.headdContainer {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  max-width: 80%;
  min-width: 70%;
}
.headddContainer {
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-left: 0;
  width: fit-content;

  max-width: 80%;
  min-width: 70%;
}

.headerr {
  color: var(--black, #1c1c1c);
  font-weight: 600;
  line-height: 140%;

  font-size: 32px;
  text-align: left;
  display: inline-block;
  width: fit-content;
  padding: 0;
  justify-content: flex-start;
}

.platformMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.proceedButton {
  /* position: absolute;
  bottom: 36px;
  width: calc(100% - 72px); */
}

.platform {
  color: var(--Secondary-grey, #717171);
  font-family: General Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.artistt{
  width: 360px;
}

.priceTicketFormLayout {
  background: var(--Brand-Light, #fff2f2);
}
.searchContainer {
  position: relative;
  width: 100%;
  min-width: 20rem;
  margin: auto;
  border-color: #1c1c1c;
}
.label {
  color: var(--black, #101010);
  font-family: General Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 10px 0px 8px;
}
.labelWithWarning {
  padding: 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.75rem;
  background: #fff2f2;
  font-size: 0.875rem;
  margin: 0.25rem;
  margin-top: 2rem;
  color: var(--brand, #ff2459);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  max-width: 90%;
  min-width: 50%;
  margin-left: 4px;
}
.info {
  font-size: 2rem;
  display: inline;
  margin: 0.5rem;
}
.errorI {
  width: 1rem;
  height: 1rem;
  margin: 0 0.25rem;
  display: inline;
}
.sell {
  gap: 0.7rem;
}
.sellerForm {
  padding: 10rem 0;
  width: 70%;
  height: 100%;
  padding-top: 5rem;
  position: relative;
}

.second {
  position: relative;
  width: fit-content;

  width: 502px;
  /* height: 650px; */
  padding: 36px;
  margin: 104px auto 0px;
  padding-bottom: 24px;
  border-radius: 12px;
  border: 1px solid #f0f0f0;
  background: var(--White, #fff);
}
.secondd {
  position: relative;
  width: 100%;
  max-width: 55%;
  min-width: 23rem;
  height: auto;
  padding: 2rem;
  margin: auto;
}
.tickettContainer {
  width: 100%;
  padding: 1rem;
  display: flex;
  height: fit-content;
  min-width: 50%;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid var(--black, #1c1c1c);
  background: var(--white, #fff);
  margin: 0;
}
.ticketIn {
  padding: 0.25rem;
  color: var(--black, #1c1c1c);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ticketIn > .ticket-img {
  margin-right: 1rem;
}

.formContainer {
  width: 100%;
  /* max-width: 80%; */
  min-width: 70%;
  gap: 20px;
}

.priceInputTitle {
  color: var(--Black, var(--Black, #101010));
  font-family: General Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}
.ticketInfo {
  padding: 0.25rem;
  width: 100%;
  max-width: 60%;
  min-width: 15rem;
}
.eventContainer {
  width: 100%;
  min-width: 50%;
  border-color: #101010;
  border-radius: 0.5rem;
}
.proceedBut {
  display: flex;
  height: 3.5rem;
  padding: 0.5rem 0.875rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #ff2459;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
  width: 100%;
  opacity: 0.2;
  position: absolute;
  bottom: 36px;
  width: calc(100% - 72px);
}

.proceed {
  display: flex;
  height: 3.5rem;
  padding: 0.5rem 0.875rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #ff2459;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
  width: 100%;
  position: absolute;
  bottom: 20px;
  width: calc(100% - 72px);
  color: var(--White, #fff);
  text-align: center;
  font-family: "General Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.inactive {
  background:#ff245b5b; ;
}
.closeButton {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  cursor: pointer;
}
.inputContainer {
  width: auto;
}
.field {
  display: flex;
  gap: 1rem;
  align-items: center;
}
/* .field > .label{
    flex:0 0 1;
    min-width: 8rem;

  }
  .field > .frame{
    flex:1;
  }  */
.frame {
  position: relative;
  margin: 0.1rem;
  width: 100%;
  font-size: 1rem;
  height: fit-content;
  padding: 1rem 1.25rem;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--field-stroke, #d0d5dd);
  background: var(--white, #fff);
  padding-right: 1rem;
}
.rupee {
  color: var(--Black, var(--Black, #101010));
  font-family: General Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  margin-right: 4px;
}
.divider {
  color: #ebebeb;
  margin: 0.25rem;
}
.divtick {
  white-space: nowrap;
  color: var(--Black, var(--Black, #101010));
  font-family: General Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  height: 36px;
  align-items: center;
  display: flex;
  border-left: 1px solid #ebebeb;
  padding-left: 16px;
}

.wp {
  display: inline;
}
.Input {
  width: 100%;
  display: flex;
  color: #1c1c1c;
}

.Input::placeholder {
  color: var(--Grey-80, #a1a1a1);
  font-family: "General Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
}
.priceInput {
  padding: 9px 20px;
  color: #1c1c1c;
}

.tagline {
  max-width: 90%;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.price {
  font-weight: 600;
}

.suggestedPriceContainer {
  cursor: pointer;
  width: 100%;
  display: flex;
  gap: 5%;
  margin-top: 0.5rem;
  align-items: baseline;
  justify-content: space-between;
}

.suggestedPrice {
  border-radius: 8px;
  border: 1px solid var(--Date-Stroke, #f0f0f0);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  height: 46px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  width: 32%;
  color: var(--Black, var(--Black, #101010));
  font-family: General Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  position: relative;
}

.suggestedPrice:hover {
  border: 1px solid var(--Brand, #ff2459);
}
.focusState {
  border: 1px solid var(--Brand, #ff2459);
  background: var(--Brand-Light, #fff2f2);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.priceBadge {
  color: var(--White, #fff);
  font-family: General Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 13.75px */
  letter-spacing: 0.11px;
  padding: 4px 16px;
  border-radius: 0px 0px 8px 8px;
  background: var(--Brand, #ff2459);
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -12px;
}

.text,
.wp,
.mail,
.categoryInput,
.numberInput,
.Input,
.priceInput option {
  color: #1c1c1c;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  width: 100%;
}
.frame:focus,
.priceInput:focus,
.wp:focus {
  border-color: black;
}
.Input:focus {
  border-color: #1c1c1c;
}

.submitButtondiv {
  /* position: absolute; */
  width: 430px;
  /* bottom: 36px; */
}

.submitButtondiv2{
  margin-top: 35px;
}
.saveButton,
.submitButton {
  margin: 0.25rem auto;
  display: flex;
  height: 3rem;
  padding: 0.1rem 0.5rem;
  align-items: center;
  gap: 8px;
  flex: 1 0;
  border-radius: 0.5rem;
  width: 50%;
  justify-content: center;
  font-weight: 600;
}

.btndisabled {
  opacity: 0.4;
  cursor: not-allowed !important;
  pointer-events: none;
}
.sec-button {
  justify-content: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  color: #fff;
  background: var(--brand, #ff3c6b);
  width: 100%;
  text-align: center;
  height: 56px;
}
.mail {
  width: 100%;
}
.saveButton {
  margin-top: 2rem;
  border-radius: 0.5rem;
  background: var(--brand, #ff2459);
  color: white;
  padding: 0.5rem 1rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  transition: opacity 0.3s ease;
}

.saveButton:disabled {
  opacity: 0.4;
  background: var(--brand, #ff2459);
  pointer-events: none;
}

.saveButton:enabled:hover {
  opacity: 1;
}

.backBtn {
  flex: 2;
  text-align: justify;
  color: #000;
}

.errorMessage {
  color: #df1b41;
  font-size: 0.875rem;
  margin-top: 0.55rem;
  display: flex;
  align-items: center;
  gap: 4px;
}

.error {
  border-color: #df1b41;
}
.AccContainer {
  position: relative;
  width: 75%;
  max-width: 50rem;
  min-width: 23rem;
  height: auto;
  padding: 5rem 2rem;
  margin: auto;
}

.serviceContainer {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.serviceApp {
  display: flex;
  padding: 0.75rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--White, #fff);
  margin: 0.5rem;
  margin-left: 0;
  width: 100%;
  min-width: 50%;
  border-radius: 8px;
  border: 1px solid var(--Field-Stroke, #d0d5dd); 
  background: var(--White, #FFF);
  cursor: pointer;
  transform: all ease-in-out;
}

.serviceAppActive {
  display: flex;
  padding: 0.75rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid black;
  background: var(--White, #fff);
  margin: 0.5rem;
  margin-left: 0;
  width: 100%;
  min-width: 50%;
  border-radius: 8px;
  border: 1px solid var(--Black, #101010);
}

.serviceLogo {
  width: 3rem;
  height: 3rem;
}

.serviceName {
  color: #000;
  font-family: General Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup {
  position: relative;
  width: 50%;
  max-width: 40rem;
  padding: 1.75rem;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: auto;
  max-height: 70rem;
}

.popup-title {
  color: var(--black, #101010);
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 140%;
  font-style: normal;
}
.popclose {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  display: inline;
}
.ss {
  font-size: 1.25rem;
}
.popinfo {
  font-size: 0.875rem;
}

.popup-message {
  display: flex;
  padding: 1.25rem;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--bg, #fff2f2);
  color: var(--black, #101010);
  margin-bottom: 1rem;
}
a {
  text-decoration: underline;
  color: #1c1c1c;
}

.popup-checkbox {
  margin-top: 1rem;
  margin: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.popup-checkbox label {
  color: var(--black, #101010);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}

.popup-checkbox label span {
  color: #101010;
}

.popup-checkbox input[type="checkbox"] {
  margin-right: 8px;
  border: 2px solid #000;
  padding: 4px;
  --checked-color: #ff2459;
}
input[type="checkbox"]:checked {
  border-color: #101010;
  background-color: var(--checked-color);
}
input[type="checkbox"]::after {
  border-color: #101010;
  background-color: var(--checked-color);
}

.checkk {
  width: 1.25rem;
  height: 1.25rem;
}

.popup-button {
  margin: 0.75rem auto;
  display: flex;
  border-radius: 0.5rem;
  background: var(--brand, #ff2459);
  cursor: pointer;
  height: 3.5rem;
  padding: 0.1rem 0.5rem;
  align-items: center;
  gap: 8px;
  flex: 1 0;
  width: 50%;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;
}

.popup-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.listingContainer {
  position: relative;
  width: 45%;
  max-width: 100%;
  min-width: 23rem;
  height: auto;
  padding: 5rem 0;

  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gifContainer {
  width: 70%;
  height: 55%;
}
@keyframes playOnce {
  to {
    visibility: hidden;
  }
}
.gifEmbed {
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  width: 18rem;
  height: 18rem;
  border-radius: 2px;
  animation: playOnce;
}

.headline {
  font-size: 2rem;
  width: 100%;
  font-weight: bold;
  margin: 0.5rem auto;
  color: #1c1c1c;
  text-align: center;
}

.message {
  width: 100%;
  font-size: 1.2rem;
  text-align: left;
  color: #717171;
}

.primaryButton {
  display: flex;
  margin: 0.5rem;
  height: 3.5rem;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  border: 1px solid var(--brand, #ff3c6b);
  background: var(--white, #fff);
  color: #ff3c6b;
  width: fit-content;
}
.verifiedContainer {
  display: inline;
  text-align: right;
}

.labelWarning {
  width: 100%;
  height: fit-content;
  background-color: #e5fff6;
  color: #101010;
  padding: 12px 20px 12px 20px;
  border-radius: 8px;
  margin-top: 30px;
}
.labelWarningBold {
  font-weight: 600;
}

.sellerformButton {
  position: absolute;
  width: 85%;
  bottom: 36px;
}

.buttonContainer1{
  position: static;
}

@media screen and (max-width: 600px) {
  .artistt{
    width: 260px;
  }

  .priceTicketFormLayout {
    background: var(--white);
  }

  .backgroundLayout {
    background: #fff;
  }
  .second {
    width: 100%;
    margin: 72px auto 0px;
    border: none;
    padding: 0px;
    /* height: 585px; */
  }

  .priceBadge {
    padding: 2px 15px;
  }
  .submitButtondiv {
    /* position: absolute; */
    width: 303px;
  }
 
  .submitButtondiv2{
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0px 24px 24px;
  }


  .sellerformButton {
    position: absolute;
    width: 85%;
    bottom: 36px;
  }
  h3 {
    font-size: 0.9rem;
    font-weight: 600;
  }
  p {
    font-size: 0.75rem;
  }
  .AccContainer {
    width: 60%;
    position: relative;
    width: 60%;
    max-width: 70%;
    min-width: 20rem;
    padding: 5rem 0;
    height: auto;
    margin: auto;
  }
  .serviceContainer{
    flex-direction: column;
    align-self: stretch;
  }
  .headContainer {
    /* margin: 1.5rem auto 0.1rem; */
    padding: 0;
  }
  .headdContainer {
    margin-top: 1.5rem;
    width: fit-content;
    padding: 0;
  }
  .headdPlatform {
    width: auto;
    margin-left: 24px;
    margin-top: 6rem;
  }
  .headddContainer {
    margin-top: 1.5rem;
    width: fit-content;
    padding: 0;
    padding-left: 1rem;
  }
  .formContainer {
    width:auto;
    margin: 24px;
    margin-top: 12px;
  }
  .headerr {
    font-size: 24px;
    font-weight: 600;
    width: fit-content;
    margin: 0;
  }
  .searchContainer {
    height: 100vh;
    width: auto;
    margin: 24px;
    margin-top: 6rem;
  }

  .tickettContainer {
    margin: 0.1rem;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  .eventContainer {
    width: 100%;
    max-width: 100%;
  }
  .inputContainer {
    width: 100%;
  }
  .sell {
    display: block;
    gap: 0.4rem;
  }
  .priceInput {
    padding: 0.2rem;
  }
  .suggestedPriceContainer {
    max-width: none;
  }
  .label {
    font-size: 0.9rem;
    padding: 0.25rem;
    padding-top: 0.75rem;
  }
  .labell {
    font-size: 1rem;
    padding: 20px 0px 6px;
  }
  .labelWithWarning {
    padding: 0.75rem;
    color: var(--brand, #ff2459);
    font-family: General Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }

  .submitButton {
   padding: 8px 14px;
  }
  .info {
    margin: 0.5rem;
    margin-top: 0;
    display: inline;
  }
  .errorI {
    width: 1rem;
    height: 1rem;
    margin: 0;
  }
  .ticketContainer {
    max-width: 20rem;
    min-width: 18rem;
    padding: 0.5rem;
    margin: 0.5rem;
  }
  .closeButton {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    cursor: pointer;
  }
  .flex {
    display: flex;
  }
  .numberInput,
  .priceInput {
    font-size: 1rem;
    padding: 0.1rem;
  }
  .ticketInfo {
    padding: 0.25rem;
    width: 100%;
    max-width: 60%;
    min-width: 15rem;
  }
  .artist {
    display: flex;
    flex-direction: column;
    color: var(--black, #1c1c1c);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: normal;
  }

  .frame {
    width: 100%;
    font-size: 0.9rem;
    max-width: 100%;
    padding: 0.75rem;
    min-width: 60%;
  }
  .saveButton {
    width: 100%;
    max-width: 100%;
  }
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup {
    width: 80%;
    max-width: 21rem;
    min-width: 16rem;
    height: fit-content;
    padding: 1.5rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  .popup-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .ss {
    font-size: 1rem;
  }

  .popup-message {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    display: block;
    gap: 0.25rem;
  }
  .popup-checkbox {
    margin: 1px;
  }
  .popup-checkbox label {
    color: var(--black, #101010);
    font-size: 0.75rem;
    margin-left: 8px;
  }
  a {
    text-decoration: underline;
    color: #101010;
    font-size: 0.75rem;
  }
  .popup-checkbox input[type="checkbox"] {
    margin-right: 4px;
    width: 1.25rem;
    height: 1.25rem;
  }
  .popup-button {
    padding: 0.5rem;
    height: 3.5rem;
    border: none;
    border-radius: 4px;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.5rem;
  }
  .popup-button:disabled {
    margin-top: 1.25rem;
  }
  .listingContainer {
    width: 75%;
    max-width: 80%;
    min-width: 60%;
    height: auto;
  }
  .gifContainer {
    width: 80%;
    height: 60%;
  }
  .gifEmbed {
    width: 16rem;
    height: 16rem;
    border-radius: 2px;
  }
  .headline {
    font-size: 1.2rem;
    display: inline;
    text-align: left;
    margin: 1rem auto;
  }
  .message {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
  .primaryButton {
    width: 100%;
  }
  .serviceApp {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 16px;
    margin: 0;
    align-self: stretch;
  }

  .buttonContainer1{
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    background-color: white;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0px 24px 24px;
    height: 110px;
   }

  .proceedButton{
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 0px;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0;
  }

  .proceed{
    width: 100%;
    bottom: 0;
  }

}

.emailDiv {
  background-color: #f5f5f5;
}
